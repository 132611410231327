import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { Modal } from '@redq/reuse-modal';
import { appTheme } from 'common/theme/app';
import {
  GlobalStyle,
  AppWrapper,
} from 'containers/App/app.style';
import { ResetCSS } from 'common/assets/css/style';
import Navbar from 'containers/App/Navbar';
import Footer from 'containers/App/Footer';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import Helmet from 'react-helmet';
import Container from 'common/components/UI/Container';

const ContactPage = () => (
  <ThemeProvider theme={appTheme}>
    <Fragment>
      <Helmet>
      </Helmet>
      <Modal />
      <ResetCSS />
      <GlobalStyle />
      <AppWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
        </Sticky>
        {/* <DomainSection /> */}
        <Container>
<h1>Contact Us</h1>
<p>If you have any questions, You can contact us:</p>
<ul style={{paddingBottom: '300px'}}>
<li>By email: shoffinn@gmail.com</li>
</ul>
</Container>
        <Footer />
      </AppWrapper>
    </Fragment>
  </ThemeProvider>
);

export default ContactPage;
